<script>
import ResourceFrame from "../components/ResourceFrame.vue";
import { SuawInputGroup, SuawParagraph } from "@suaw/suaw-component-library";
export default {
  name: "ResourcesContact",
  components: { ResourceFrame, SuawInputGroup, SuawParagraph }
};
</script>

<template>
  <ResourceFrame>
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-contact-heading"><b>Contact Us</b></h1>
      <SuawParagraph text="For questions about a specific Shut Up & Write event, please reach out to the event host." />
      <SuawParagraph
        text='For general questions about our organization, feel free to email us at <a href="mailto:questions@shutupwrite.com">questions@shutupwrite.com</a>. Our help desk is available Monday through Friday, 9am - 5pm Pacific time.'
      />
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-contact-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-contact-intro {
  font-family: Roboto;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  color: var(--sem-color-neutral-dark);
}
.suaw-contact-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
//list
.suaw-contact-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
